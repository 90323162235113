<template>
<div class="main-layout" :class="{'faded' : g_game_state === 'flow'}">
  <div style="z-index: 2000;" v-if="USER_ME === ''" class="position-fixed top-0 w-100 h-100 bg-dark d-flex align-items-center justify-content-center">
    <div class="d-flex align-items-center justify-content-center flex-column">
      <b-spinner class="mb-3" style="height: 80px; width: 80px;" variant="primary" label="Spinning"></b-spinner>
      <p class="mb-0 font-weight-bold text-gray-200">
        Login...
      </p>
    </div>
  </div>

  <main-header v-if="!['waiting', 'loading', 'flow'].includes(g_game_state)"></main-header>
<!--  <b-container>-->
    <slot />
<!--  </b-container>-->

<b-sidebar backdrop style="font-size: 1rem" :bg-variant="isWhiteTheme ? 'white' : 'dark'" id="sidebar-right" right shadow height="100%">
    <div :class="{'tigpok_white': isWhiteTheme}" class="px-3 py-2 d-flex flex-column">
     <b-list-group flush>
      <b-list-group-item class="cursor-pointer bg-dark text-light">
        <router-link to="/dashboard" class="text-light">{{ $t('dashboard') }}</router-link>
      </b-list-group-item>

      <b-list-group-item v-if="!$store.state.safe_mode" class="cursor-pointer bg-dark text-light">
        <router-link to="/payments" class="text-light">{{ $t('add_money') }}</router-link>
      </b-list-group-item>

      <b-list-group-item class="cursor-pointer bg-dark text-light" @click="$bvModal.show('modal-rules')">
        <b-link variant="light">{{ $t('game_rules') }}</b-link>
      </b-list-group-item>

      <b-list-group-item class="cursor-pointer bg-dark text-light" @click="$store.dispatch('LOG_OUT')">
        <b-link class="text-light">{{ $t('log_out') }}</b-link>
      </b-list-group-item>
     </b-list-group>
    </div>

    <locale-selector class="ml-4" />
  </b-sidebar>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import main_header from '@/components/main_layout_components/main_header.vue';

export default {
  data: () => ({
  }),

  components: {
    "main-header": main_header
  },

  computed: {
    ...mapGetters(['USER_ME', 'g_game_state'])
  }
}
</script>