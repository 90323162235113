import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

function checkAuth(to, from ,next){
  var auth = JSON.parse(localStorage.getItem('tigpok_login'))
  if(auth === true){
    store.dispatch('ME')
    next()
  } else {
    next('/login')
  }
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {layout: "main" },
    beforeEnter: checkAuth,
    component: () => import('../views/Home.vue')
  },

  {
    path: '/login',
    name: 'Login',
    meta: {layout: "auth" },
    component: () => import('../views/Login.vue')
  },

  {
    path: '/sign-up',
    name: 'Sign-up',
    meta: {layout: "auth" },
    component: () => import('../views/Sign_up.vue')
  },

  {
    path: '/payments',
    name: 'Payments',
    meta: {layout: "main" },
    beforeEnter: checkAuth,
    component: () => import('../views/payments')
  },

  {
    path: '/recovery_password',
    name: 'Recovery password',
    meta: {layout: "auth" },
    component: () => import('../views/recovery_password')
  },

  {
    path: '/account_activate',
    name: 'Account_activate',
    meta: {layout: "auth" },
    component: () => import('../views/account_activate')
  },

  {
    path: '/reset_password',
    name: 'Reset password',
    meta: {layout: "auth" },
    component: () => import('../views/reset_password')
  },

  {
    path: '/successful-payment',
    name: 'Successful payment',
    meta: {layout: "main" },
    beforeEnter: checkAuth,
    component: () => import('../views/successful_payment')
  },

  {
    path: '/failed-payment',
    name: 'Failed payment',
    meta: {layout: "main" },
    beforeEnter: checkAuth,
    component: () => import('../views/failed_payment')
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {layout: "main"},
    beforeEnter: checkAuth,
    component: () => import('../views/dashboard')
  },

  {
    path: '/casting_enter',
    name: 'CastingEnter',
    meta: {layout: "main"},
    beforeEnter: checkAuth,
    component: () => import('../views/casting_enter')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const foundRoute = router.options.routes.find(route => route.path === to.path);

  if (foundRoute) {
    // The route is defined in router.js, proceed with navigation
    next();
  } else {
    // The route is not defined in router.js, redirect to login page
    next('/');
  }
});

export default router
