import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  en: {
    // General
    loading: 'Loading...',
    group: 'Group',
    white_theme:'White theme',
    withdrawal : 'Withdrawal',
    withdraw_funds: 'Withdraw funds',
    amount: 'Amount',
    minimum_amount_withdraw :'The minimum withdrawal amount is 100.',
    withdrawal_wallet_alert: 'Funds will be transferred to the specified wallet. In case of incorrect data, the organizers are not responsible for any losses.',
    withdraw: 'Withdraw',
    wallet_address: 'Wallet address',
    payments: 'Payments',
    account: 'Account',
    no_payments: 'No payments',
    change_nick: 'Change nickname',
    username: 'Username',
    change_password: 'Change password',
    old_password: 'Old password',
    new_password: 'New password',
    confirm_password: 'Confirm password',
    save: 'Save',

    // Header
    game_rules: 'Rules',
    add_money: 'Add money',

    how_to_buy_usdt: 'How to buy USDT (using Trust Wallet as an example)',
    add_money_to_balance: 'Add funds to balance',
    add_money_tip: 'You will be redirected to the cryptocloud payment page.',
    pay: 'Pay',
    go_home: 'Go home',
    close: 'Close',
    log_out: 'Log Out',
    dashboard: 'Dashboard',

    // Game start and setup
    startVideo: 'Start video',
    hi: 'Hi',
    turnOnCamera: 'To start the game please turn on the camera',
    play: 'Play',
    bonusGame: 'Bonus Game',
    chooseBet: 'Choose bet',
    cancel: 'Cancel',
    betRequiredForBonusGame: 'A bet of 5 is required to play the bonus game. The bet will automatically be set to 5 when you select the bonus game.',
    
    // Game states
    waitingForPlayers: 'Waiting for players',
    tryingToConnect: 'Trying to connect',
    videoUnavailable: 'The game has started but the video is not available, use the chat',
    stop: 'Stop',
    waitForResponse: 'Wait for response',
    waitForCall: 'Wait for call',
    waitForDiscussion: 'Waiting for {name1} and {name2} to finish their discussion.',
    
    // Chat
    writeMessage: 'Write message',
    
    // Exile phase
    chooseWhoseBetToSplit: 'Choose whose bet to split',
    chooseAll: 'Choose all',
    youVotedAgainst: 'You voted against {name}',
    youVotedAgainstMultiple: 'You voted against {names}',
    
    // Results
    youWon: 'You won!!',
    youLost: 'You lost',
    draw: 'Draw',
    decisions: 'Decisions',
    player: 'Player',
    against: 'Against',

    // casting enter
    castingEnter: 'Casting enter',
    enterRules: 'Select the images in the correct order',
    startOver: 'Start over',
    accept: 'Accept',
    return: 'Return',
    reset: 'Reset',
    submit: 'Submit',
    continue: 'Continue',

    // Tips
    tips: {
      tip1: "Success is the art of negotiating with fate",
      tip2: "The best strategy in Tigpok: play like a pro, but bet like a beginner.",
      tip3: "There is always a best strategy in Tigpok - just don't let your opponents know what that strategy is.",
      tip4: "In the Tigpok game, as in politics: personal charm is a weapon, and lies are a tool.",
      tip5: "It is not surprising that those who tell the truth often win in Tigpok, because the truth is also a weapon.",
      tip6: "Tigpok is a theater, and all players are actors.",
      tip7: "Psychology is an important tool in recognizing lies, but even more important in recognizing the truth.",
      tip8: "The ability to come to an agreement with any person is one of the most important traits in a person's success; you just need to monetize it.",
      tip9: "Trust is not an important strategy in life, and especially not in this game.",
      tip10: "Quotes are the haiku of propagandists.",
      tip11: "Life is an eternal Tigpok, you always choose who to trust. Why not make money from it?",
      tip12: "Tigpok is a mirror in which we see the reflection of our decisions.",
      tip13: "At Tigpok we explore the world not only outside, but also inside ourselves.",
      tip14: "Tigpok is a path to understanding life, where the rules are created and changed by us.",
      tip15: "Tigpok teaches us to make decisions and learn from mistakes, just like in real life.",
      tip16: "Through Tigpok we can experience different roles and understand others' experiences, which promotes empathy and understanding.",
      tip17: "People trust more those who are successful.",
      tip18: "Tigpok confirms the end and justifies the means.",
      tip19: "Sometimes a trick is the only way to get what you deserve.",
      tip20: "Success is not just an accident.",
      tip21: "People love to communicate, deceive each other and make money from communication and lies - all of this is in Tigpok.",
      tip22: "Convincing people to trust you and then making money from it is what politics and Tigpok have in common.",
      tip23: "The world has many problems, but even more opportunities.",
      tip24: "Opportunities always lie with those who seek them.",
      tip25: "You don't need great opportunities to get started. Start with what you have and they will come as you move forward.",
      tip26: "Knowing yourself helps you change yourself, adaptation is the key to survival in Tigpok.",
      tip27: "A bird relies on its wings, a man on strategy.",
      tip28: "The best moment to act is now.",
      tip29: "The desire for victory without a plan is just a dream.",
      tip30: "Luck is a choice, not a result of circumstances.",
      tip31: "We learn from mistakes, we grow with experience.",
      tip32: "The weak look for excuses, the strong prepare them in advance.",
      tip33: "Tired of sitting without money? Get up!",
      tip34: "Don't be afraid to fight someone who doesn't know how.",
      tip35: "You live as well as you can negotiate."
    }
  },
  ru: {
    // General
    loading: 'Загрузка...',
    group: 'Группа',
    white_theme:'Белая тема',
    withdrawal: 'Вывод средств',
    withdraw_funds: 'Вывести средства',
    amount: 'Сумма',
    minimum_amount_withdraw :'Минимальная сумма снятия составляет 100.',
    withdrawal_wallet_alert: 'Средства будут переведены на указанный кошелек. В случае указания неверных данных организаторы не несут ответственности за любые потери.',
    withdraw: 'Вывести',
    wallet_address: 'Номер кошелька',
    payments: 'Платежи',
    no_payments: 'Платежи отсутствуют',
    account: 'Аккаунт',
    change_nick: 'Изменить имя пользователя',
    username: 'Имя пользователя',
    change_password: 'Сменить пароль',
    old_password: 'Старый пароль',
    new_password: 'Новый пароль',
    confirm_password: 'Подтвердите пароль',
    save: 'Сохранить',

    // Header
    game_rules: 'Правила',
    add_money: 'Пополнить счет',

    how_to_buy_usdt: 'Как купить USDT (на примере кошелька Trust Wallet)',
    add_money_to_balance: 'Пополнить баланс',
    add_money_tip: 'Вы будете перенаправлены на страницу оплаты cryptocloud.',
    pay: 'Пополнить',
    go_home: 'На главную',
    close: 'Закрыть',
    log_out: 'Выйти',
    dashboard: 'Панель управления',

    // Game start and setup
    startVideo: 'Включить камеру',
    hi: 'Привет',
    turnOnCamera: 'Для начала игры включите камеру',
    play: 'Играть',
    bonusGame: 'Бонус Игра',
    chooseBet: 'Выберите ставку',
    cancel: 'Отмена',
    betRequiredForBonusGame: 'Для начала игры необходимо сделать ставку в 5 монет. Ставка автоматически будет установлена в 5 монет при выборе бонусной игры.',
    
    // Game states
    waitingForPlayers: 'Ожидание игроков',
    tryingToConnect: 'Попытка подключения',
    videoUnavailable: 'Игра началась, но видео недоступно, используйте чат',
    stop: 'Стоп',
    
    // Chat
    writeMessage: 'Написать сообщение',
    
    // Exile phase
    chooseWhoseBetToSplit: 'Выберите чью ставку разделить',
    chooseAll: 'Выбрать всех',
    youVotedAgainst: 'Вы проголосовали против {name}',
    youVotedAgainstMultiple: 'Вы проголосовали против {names}',
    
    // Results
    youWon: 'Вы выиграли!!',
    youLost: 'Вы проиграли',
    draw: 'Ничья',
    decisions: 'Решения',
    player: 'Игрок',
    against: 'Против',

    // casting enter
    castingEnter: 'Вход на кастинг',
    enterRules: 'Выберите картинки в правильном порядке',
    startOver: 'Начать заново',
    accept: 'Принять',
    return: 'Вернуться',
    reset: 'Сбросить',
    submit: 'Отправить',
    continue: 'Продолжить',

    // Tips
    tips: {
      tip1: "Успех - это искусство переговоров с судьбой",
      tip2: "Лучшая стратегия в Tigpok: играй как профессионал, но ставь как новичок.",
      tip3: "В Tigpok всегда есть лучшая стратегия - просто не дай противникам узнать, какая именно.",
      tip4: "В игре Tigpok, как и в политике: личное обаяние - это оружие, а ложь - инструмент.",
      tip5: "Неудивительно, что те, кто говорит правду, часто побеждают в Tigpok, потому что правда - тоже оружие.",
      tip6: "Tigpok - это театр, а все игроки - актеры.",
      tip7: "Психология - важный инструмент в распознавании лжи, но еще важнее в распознавании правды.",
      tip8: "Умение договориться с любым человеком - одна из важнейших черт в успехе человека; нужно только монетизировать это.",
      tip9: "Доверие - не важная стратегия в жизни, а особенно в этой игре.",
      tip10: "Цитаты - это хайку пропагандистов.",
      tip11: "Жизнь - вечный Tigpok, ты всегда выбираешь, кому доверять. Почему бы не заработать на этом?",
      tip12: "Tigpok - это зеркало, в котором мы видим отражение наших решений.",
      tip13: "В Tigpok мы исследуем мир не только снаружи, но и внутри себя.",
      tip14: "Tigpok - это путь к пониманию жизни, где правила создаются и меняются нами.",
      tip15: "Tigpok учит нас принимать решения и учиться на ошибках, как в реальной жизни.",
      tip16: "Через Tigpok мы можем испытать разные роли и понять опыт других, что способствует эмпатии и пониманию.",
      tip17: "Люди больше доверяют тем, кто успешен.",
      tip18: "Tigpok подтверждает цель и оправдывает средства.",
      tip19: "Иногда хитрость - единственный способ получить то, что ты заслуживаешь.",
      tip20: "Успех - это не просто случайность.",
      tip21: "Люди любят общаться, обманывать друг друга и зарабатывать на общении и лжи - все это есть в Tigpok.",
      tip22: "Убеждать людей доверять тебе, а потом зарабатывать на этом - то, что объединяет политику и Tigpok.",
      tip23: "В мире много проблем, но еще больше возможностей.",
      tip24: "Возможности всегда лежат с теми, кто их ищет.",
      tip25: "Не нужны большие возможности, чтобы начать. Начни с того, что есть, и они придут по мере движения вперед.",
      tip26: "Знание себя помогает изменить себя, адаптация - ключ к выживанию в Tigpok.",
      tip27: "Птица полагается на крылья, человек на стратегию.",
      tip28: "Лучший момент для действия - сейчас.",
      tip29: "Желание победы без плана - просто мечта.",
      tip30: "Удача - это выбор, а не результат обстоятельств.",
      tip31: "Мы учимся на ошибках, растем с опытом.",
      tip32: "Слабые ищут оправдания, сильные готовят их заранее.",
      tip33: "Надоело сидеть без денег? Встань!",
      tip34: "Не бойся драться с тем, кто не умеет.",
      tip35: "Живешь настолько хорошо, насколько умеешь договариваться."
    }
  },
  ko: {
    // General
    loading: '로딩 중...',
    group: '그룹',
    white_theme: '화이트 테마',
    withdrawal: '출금',
    withdraw_funds: '자금 인출',
    amount: '금액',
    minimum_amount_withdraw: '최소 출금 금액은 100입니다.',
    withdrawal_wallet_alert: '자금은 지정된 지갑으로 이체됩니다. 잘못된 데이터를 입력한 경우 주최측은 손실에 대해 책임지지 않습니다.',
    withdraw: '출금',
    wallet_address: '지갑 주소',
    payments: '결제',
    account: '계정',
    no_payments: '결제 내역 없음',
    change_nick: '닉네임 변경',
    username: '사용자 이름',
    change_password: '비밀번호 변경',
    old_password: '이전 비밀번호',
    new_password: '새 비밀번호',
    confirm_password: '비밀번호 확인',
    save: '저장',

    // Header
    game_rules: '규칙',
    add_money: '자금 추가',

    how_to_buy_usdt: 'USDT 구매 방법 (Trust Wallet 예시)',
    add_money_to_balance: '잔액에 자금 추가',
    add_money_tip: 'cryptocloud 결제 페이지로 이동됩니다.',
    pay: '결제',
    go_home: '홈으로',
    close: '닫기',
    log_out: '로그아웃',
    dashboard: '대시보드',

    // Game start and setup
    startVideo: '비디오 시작',
    hi: '안녕하세요',
    turnOnCamera: '게임을 시작하려면 카메라를 켜주세요',
    play: '플레이',
    bonusGame: '보너스 게임',
    chooseBet: '베팅 선택',
    cancel: '취소',
    betRequiredForBonusGame: '보너스 게임을 플레이하려면 5의 베팅이 필요합니다. 보너스 게임을 선택하면 베팅이 자동으로 5로 설정됩니다.',

    // Game states
    waitingForPlayers: '플레이어 대기 중',
    tryingToConnect: '연결 시도 중',
    videoUnavailable: '게임이 시작되었지만 비디오를 사용할 수 없습니다. 채팅을 이용해주세요',
    stop: '정지',
    waitForResponse: '응답 대기',
    waitForCall: '통화 대기',
    waitForDiscussion: '{name1}님과 {name2}님의 토론이 끝날 때까지 기다리는 중입니다.',

    // Chat
    writeMessage: '메시지 작성',

    // Exile phase
    chooseWhoseBetToSplit: '누구의 베팅을 나눌지 선택하세요',
    chooseAll: '모두 선택',
    youVotedAgainst: '{name}님에 대해 반대 투표하셨습니다',
    youVotedAgainstMultiple: '{names}님들에 대해 반대 투표하셨습니다',

    // Results
    youWon: '승리했습니다!!',
    youLost: '패배했습니다',
    draw: '무승부',
    decisions: '결정',
    player: '플레이어',
    against: '반대',

    // casting enter
    castingEnter: '캐스팅 입장',
    enterRules: '올바른 순서로 이미지를 선택하세요',
    startOver: '다시 시작',
    accept: '수락',
    return: '돌아가기',
    reset: '초기화',
    submit: '제출',
    continue: '계속',

    // Tips
    tips: {
      tip1: "성공은 운명과 협상하는 예술입니다",
      tip2: "Tigpok의 최고의 전략: 프로처럼 플레이하되, 초보자처럼 베팅하세요.",
      tip3: "Tigpok에는 항상 최고의 전략이 있습니다 - 단지 그것이 무엇인지 상대방이 모르게 하세요.",
      tip4: "Tigpok 게임에서는 정치와 마찬가지로: 개인의 매력은 무기이고, 거짓말은 도구입니다.",
      tip5: "진실을 말하는 사람들이 Tigpok에서 자주 승리하는 것은 놀랍지 않습니다. 진실 또한 무기이기 때문입니다.",
      tip6: "Tigpok에서는 모든 것이 가능합니다 - 단지 당신의 상상력이 한계일 뿐입니다.",
      tip7: "게임에서 가장 중요한 것은 승리가 아닙니다 - 그것은 당신이 어떻게 플레이하느냐입니다.",
      tip8: "Tigpok에서 가장 강력한 무기는 당신의 마음입니다.",
      tip9: "게임은 단순한 오락이 아닙니다 - 그것은 삶의 축소판입니다.",
      tip10: "승리하기 위해서는 때로는 패배할 준비가 되어 있어야 합니다.",
      tip11: "Tigpok에서 가장 위험한 적은 당신 자신입니다.",
      tip12: "게임의 규칙을 아는 것은 중요하지만, 그것을 어떻게 해석하느냐가 더 중요합니다.",
      tip13: "진정한 승자는 패배에서도 배우는 사람입니다.",
      tip14: "때로는 가장 단순한 전략이 가장 효과적입니다.",
      tip15: "Tigpok에서 성공하는 비결: 침착함을 유지하고, 명확하게 생각하세요.",
      tip16: "모든 게임은 새로운 기회입니다.",
      tip17: "실수는 교훈이 되고, 승리는 경험이 됩니다.",
      tip18: "당신의 직감을 믿으세요 - 그것은 당신의 경험의 목소리입니다.",
      tip19: "Tigpok에서 가장 좋은 방어는 때로는 공격입니다.",
      tip20: "게임의 진정한 즐거움은 도전에 있습니다.",
      tip21: "승리는 준비된 자의 것입니다.",
      tip22: "전략적 사고는 배울 수 있는 기술입니다.",
      tip23: "인내심은 Tigpok의 숨겨진 무기입니다.",
      tip24: "모든 결정에는 결과가 따릅니다 - 현명하게 선택하세요.",
      tip25: "게임은 시작될 때가 아니라 끝날 때 승부가 결정됩니다."
    },

    // Rules
    rules: {
      title: "Tigpok 게임 규칙",
      description: "Tigpok은 전략적 사고와 심리적 기술이 필요한 독특한 게임입니다.",
      basic: {
        title: "기본 규칙",
        rules: [
          "게임은 2-4명의 플레이어와 함께 진행됩니다",
          "각 플레이어는 게임 시작 시 동일한 금액의 칩을 받습니다",
          "게임의 목표는 다른 플레이어들의 칩을 획득하는 것입니다",
          "게임은 여러 라운드로 구성되며, 각 라운드마다 새로운 전략적 기회가 주어집니다"
        ]
      },
      gameplay: {
        title: "게임 진행",
        rules: [
          "각 라운드는 베팅 단계로 시작됩니다",
          "플레이어들은 자신의 전략에 따라 베팅을 합니다",
          "투표 단계에서는 플레이어들이 서로를 평가합니다",
          "결과는 플레이어들의 결정과 투표에 따라 결정됩니다"
        ]
      },
      advanced: {
        title: "고급 규칙",
        rules: [
          "특별한 보너스 게임이 무작위로 발생할 수 있습니다",
          "연합과 협력이 허용되지만, 최종 승자는 한 명입니다",
          "게임 중 전략적 대화가 가능합니다",
          "각 결정은 게임의 결과에 영향을 미칩니다"
        ]
      }
    },

    // Errors
    errors: {
      default: '오류가 발생했습니다',
      network: '네트워크 오류가 발생했습니다',
      server: '서버 오류가 발생했습니다',
      validation: '입력 데이터가 올바르지 않습니다',
      auth: '인증 오류가 발생했습니다',
      notFound: '요청한 리소스를 찾을 수 없습니다',
      permission: '이 작업을 수행할 권한이 없습니다',
      tooManyRequests: '너무 많은 요청이 발생했습니다. 잠시 후 다시 시도해주세요'
    }
  }
}

export default new VueI18n({
  locale: 'en', // set default locale
  fallbackLocale: 'en', // set fallback locale
  messages
}) 