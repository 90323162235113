<template>
<div class="auth-layout">
  <b-container style="padding: 20px 16px">
    <div class="d-flex align-items-center">
      <img width="42" height="42" :src="require('@/assets/logo.svg')" alt="">
      <span class="logo-text ml-3">
        Tigpok
      </span>

      <locale-selector />
    </div>
  </b-container>

  <b-container class="flex-grow-1 d-flex flex-direction-column">
    <slot />
  </b-container>

</div>
</template>

<script>
export default {

}
</script>