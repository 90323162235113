<template>
    <b-container class="pt-4 overflow-auto">
      <h1>{{ $t('castingEnter') }}</h1>
  
      <p class="text-center">{{ $t('enterRules') }}</p>   
  
      <b-row>
          <b-col cols="6" lg="3" class="mb-3">
              <b-btn class="w-100 p-3" 
                     @click="updateScore(1)" 
                     :disabled="gameWon">
                     <svg class="w-100" width="630"  viewBox="0 0 630 630" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M588.949 599H41.3101C33.8451 599 29.012 591.117 32.398 584.464L311.118 36.8254C314.856 29.4812 325.374 29.5565 329.007 36.9535L597.925 584.592C601.188 591.237 596.351 599 588.949 599Z" stroke="#00CB6F" stroke-width="62"/>
                    </svg>

              </b-btn>
          </b-col>
  
          <b-col cols="6" lg="3" class="mb-3"> 
              <b-btn class="w-100 p-3" 
                     @click="updateScore(2)" 
                     :disabled="gameWon">
                     <svg class="w-100" width="616"  viewBox="0 0 616 604" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="31.5" y="31.5" width="553" height="541" rx="68.5" stroke="#EB0047" stroke-width="63"/>
                     </svg>
              </b-btn>
          </b-col>
  
          <b-col cols="6" lg="3">
              <b-btn class="w-100 p-3" 
                     @click="updateScore(3)" 
                     :disabled="gameWon">
                     <svg class="w-100" width="600"  viewBox="0 0 600 600" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="300" cy="300" r="268.5" stroke="#0036E7" stroke-width="63"/>
                     </svg>
              </b-btn>
          </b-col>
  
          <b-col cols="6" lg="3">
              <b-btn class="w-100 p-3" 
                     @click="updateScore(4)" 
                     :disabled="gameWon">
                     <svg class="w-100" width="555"  viewBox="0 0 555 554" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M31.5 523L523.5 31" stroke="#F1E100" stroke-width="62" stroke-linecap="round"/>
                            <path d="M31.5 31L523.5 523" stroke="#F1E100" stroke-width="62" stroke-linecap="round"/>
                            </svg>
              </b-btn>
          </b-col>
      </b-row>

     <b-row class="my-4">
        <b-col cols="12" lg="4" class="mb-3">
            <b-btn class="w-100 rounded-pill button-outline-primary" @click="back">
                {{ $t('return') }}
            </b-btn>
        </b-col>

        <b-col cols="12" lg="4" class="mb-3">
            <b-btn class="w-100 rounded-pill button-outline-primary" @click="resetGame">
                {{ $t('reset') }}
            </b-btn>
        </b-col>

        <b-col cols="12" lg="4" class="mb-3">
            <b-btn :disabled="!gameWon" class="w-100 rounded-pill button-gradient-primary" @click="$emit('submit')">
                {{ $t('continue') }}
            </b-btn>
        </b-col>
     </b-row>
    </b-container>
  </template>
  
  <script>
  export default {
    name: 'CastingEnter',
  
    data() {
      return {
        buttonClicks: {
          1: false,
          2: false,
          5: false,
          10: false
        },
        lastClickedButton: null,
        winMatch: [1, 3, 2, 4],
        clickedMatch: [],
      }
    },
  
    computed: {
      gameWon() {
        return JSON.stringify(this.clickedMatch) === JSON.stringify(this.winMatch)
      }
    },
  
    methods: {
      updateScore(points) {
        this.clickedMatch.push(points)
      },

      back() {
        this.$emit('back')
      },
  
      resetGame() {
        this.clickedMatch = []
        this.buttonClicks = {
          1: false,
          2: false,
          5: false,
          10: false
        }
        this.lastClickedButton = null
      }
    }
  }
  </script>