<template>
<div class="main-header" :class="{'d-none': g_game_state === 'flow'}">
  <b-container class="d-flex align-items-center main-header_container">
    <router-link class="d-none d-sm-flex align-items-center" to="/">
      <img width="42" height="42" :src="require('@/assets/logo.svg')" alt="logo">
      <span class="logo-text ml-3">
        Tigpok
      </span>
    </router-link>

    <router-link class="d-block d-sm-none" to="/">
      <img width="32" height="32" :src="require('@/assets/logo.svg')" alt="logo">
    </router-link>

    <div class="ml-auto d-flex align-items-center">
      <b-btn variant="link" class="link-gray-100 align-items-center px-0 py-0 mr-2 d-none d-md-block" v-b-modal.modal-rules>
        <span>
          {{ $t('game_rules') }}
        </span>
      </b-btn>

      <router-link v-if="!$store.state.safe_mode" to="/payments" tag="a" class="ml-1 text-primary link-gray-100 d-flex align-items-center mx-2">
        <span class="d-flex align-items-center">
          <span class="d-none d-lg-block mr-1">{{ $t('add_money') }}</span>
        </span>
      </router-link>  

      <router-link v-if="!$store.state.safe_mode" to="/payments" class="ml-1 link-gray-100 d-flex align-items-center">
        <span class="d-flex align-items-center">
          <img class="mr-1" width="20" :src="require('@/assets/token-icon.svg')" alt="">
          <span v-html="Number(USER_ME.coins).toFixed(2)" />
        </span>
        <span class="text-primary ml-1">+</span>
      </router-link>

      <span v-else class="ml-1 link-gray-100 d-flex align-items-center">
        <span class="d-flex align-items-center">
          <img class="mr-1" width="20" :src="require('@/assets/token-icon.svg')" alt="">
          <span v-html="Number(USER_ME.coins).toFixed(2)" />
        </span>
      </span>

      <span v-if="USER_ME.bonus_coins > 0" class="ml-3">
        <span class="d-flex align-items-center">
          <img class="mr-1" width="20" :src="require('@/assets/bonus_coin.svg')" alt="">
          <span v-html="Number(USER_ME.bonus_coins).toFixed(2)" />
        </span>
      </span>

      <b-btn class="ml-3 link-white px-2 pt-1 d-flex" variant="link" to="/dashboard">
        <div class="h-100">
          <b-icon class="mr-1" icon="person" />
        </div>

        <span class="d-none d-md-inline-block main-header_nick">
          {{USER_ME.nick_name}}
        </span>
      </b-btn>

      <locale-selector class="d-none d-md-block" />

      <b-btn @click="$store.dispatch('LOG_OUT')" class="link-gray-400-danger px-2 ml-3 d-none d-md-block" variant="link">
        <b-icon icon="box-arrow-right" />
      </b-btn>

      <b-btn v-b-toggle.sidebar-right class="ml-2 p-2 d-md-none" style="padding: 7px 10px 10px 10px">
        <b-icon icon="list" />
      </b-btn>
    </div>
  </b-container>

  <b-modal size="lg" id="modal-rules" :content-class="($store.state.isWhiteTheme ? 'modal-white' : '')" hide-header title="BootstrapVue">
      <div v-if="$i18n.locale === 'en'">
        <h3>Game rules</h3>
        <p class="mb-1 text-gray-100">Each game involves three players. Each player makes the same bet.</p>
        <p class="mb-1 text-gray-100">The game consists of two stages: negotiations and voting.</p>
        <p class="mb-1 text-gray-100">During the negotiation phase, each player has a limited time to video chat with each of the other players. While two players are negotiating, the third player is waiting for his turn.</p>
        <p class="mb-1 text-gray-100">After the player negotiates with each opponent, the voting stage begins for him. Every player can vote against one of the opponents or against all of them.</p>
        <p class="mb-1 text-gray-100 font-weight-bold">The results of the game are determined based on the voting results:</p>
        <ol class="mb-1">
          <li class="text-gray-100">A player takes the bets of both opponents if both opponents voted against him and he voted against all opponents.</li>
          <li class="text-gray-100">A player takes the bet of the opponent he voted against if that opponent voted against all and the other opponent voted against all or against the player.</li>
          <li class="text-gray-100">A player shares an opponent's bet equally with another player if those two players voted against the opponent and the opponent did not vote against all.</li>
          <li class="text-gray-100">A player loses his bet if both opponents vote against him and he does not vote against all opponents.</li>
          <li class="text-gray-100">A player loses his bet if he voted against all opponents, but only one opponent voted against the player.</li>
          <li class="text-gray-100">All players lose their bets if all of them voted against all.</li>
          <li class="text-gray-100">In all other cases the player returns his bet.</li>
        </ol>
        <p class="text-gray-100">
          If any player disconnects from the game before the end of voting, the game is considered canceled and bets are returned to the players.
          <span v-if="!$store.state.safe_mode">For each completed game, the platform takes a commission of 3% of each lost bet.</span>
        </p>
      </div>

      <div v-if="$i18n.locale === 'ru'">
        <h3>Правила игры</h3>
        <p class="mb-1 text-gray-100">Каждая игра включает трех игроков с одинаковой ставкой.</p>
        <p class="mb-1 text-gray-100">Игра состоит из двух этапов: переговоры и голосование.</p>
        <p class="mb-1 text-gray-100">Во время этапа переговоров каждый игрок имеет ограниченное время для видеочата с каждым из других игроков. Пока двое игроков ведут переговоры, третий игрок ждет своей очереди.</p>
        <p class="mb-1 text-gray-100">После того, как игрок проведет переговоры с каждым из соперников, для него начинается этап голосования. Каждый игрок может проголосовать против одного из соперников или против всех.</p>
        <p class="mb-1 text-gray-100 font-weight-bold">Результаты игры определяются на основе результатов голосования:</p>
        <ol class="mb-1">
          <li class="text-gray-100">Игрок забирает ставки обоих соперников, если оба соперника проголосовали против него, а он проголосовал против всех соперников.</li>
          <li class="text-gray-100">Игрок забирает ставку соперника, против которого он проголосовал, если этот соперник проголосовал против всех и другой соперник проголосовал против всех или против игрока.</li>
          <li class="text-gray-100">Игрок делит ставку соперника поровну с другим игроком, если эти два игрока проголосовали против соперника, а соперник не проголосовал против всех.</li>
          <li class="text-gray-100">Игрок теряет свою ставку, если оба соперника проголосовали против него, а он не проголосовал против всех соперников.</li>
          <li class="text-gray-100">Игрок теряет свою ставку, если он проголосовал против всех соперников, но только один соперник проголосовал против игрока.</li>
          <li class="text-gray-100">Все игроки теряют свои ставки, если все они проголосовали против всех.</li>
          <li class="text-gray-100">Во всех остальных случаях игрок возвращает свою ставку.</li>
        </ol>
        <p class="text-gray-100">
          Если любой игрок отключается от игры до окончания голосования, игра считается отмененной, и ставки возвращаются игрокам.
          <span v-if="!$store.state.safe_mode">За каждую завершенную игру платформа взимает комиссию в размере 3% от каждой проигранной ставки.</span>
        </p>
      </div>

      <div v-if="$i18n.locale === 'ko'">  
        <h3>게임 규칙</h3>
        <p class="mb-1 text-gray-100">각 게임은 동일한 베팅 금액을 가진 3명의 플레이어가 참여합니다.</p>
        <p class="mb-1 text-gray-100">게임은 협상과 투표, 두 단계로 구성됩니다.</p>
        <p class="mb-1 text-gray-100">협상 단계에서 각 플레이어는 다른 플레이어들과 제한된 시간 동안 화상 채팅을 할 수 있습니다. 두 플레이어가 협상하는 동안 세 번째 플레이어는 자신의 차례를 기다립니다.</p>
        <p class="mb-1 text-gray-100">플레이어가 각 상대와 협상을 마치면 투표 단계가 시작됩니다. 모든 플레이어는 한 명의 상대 또는 모든 상대에 대해 반대 투표를 할 수 있습니다.</p>
        <p class="mb-1 text-gray-100 font-weight-bold">게임 결과는 투표 결과에 따라 결정됩니다:</p>
        <ol class="mb-1">
          <li class="text-gray-100">두 상대 모두가 플레이어에게 반대 투표를 하고 플레이어가 모든 상대에게 반대 투표를 한 경우, 플레이어는 두 상대의 베팅을 모두 가져갑니다.</li>
          <li class="text-gray-100">플레이어가 반대 투표한 상대가 모두에게 반대 투표를 했고 다른 상대가 모두에게 또는 플레이어에게 반대 투표를 한 경우, 플레이어는 해당 상대의 베팅을 가져갑니다.</li>
          <li class="text-gray-100">두 플레이어가 한 상대에게 반대 투표를 하고 그 상대가 모두에게 반대 투표를 하지 않은 경우, 두 플레이어는 그 상대의 베팅을 균등하게 나눕니다.</li>
          <li class="text-gray-100">두 상대 모두가 플레이어에게 반대 투표를 하고 플레이어가 모든 상대에게 반대 투표를 하지 않은 경우, 플레이어는 자신의 베팅을 잃습니다.</li>
          <li class="text-gray-100">플레이어가 모든 상대에게 반대 투표를 했지만 한 명의 상대만 플레이어에게 반대 투표를 한 경우, 플레이어는 자신의 베팅을 잃습니다.</li>
          <li class="text-gray-100">모든 플레이어가 모두에게 반대 투표를 한 경우, 모든 플레이어가 자신의 베팅을 잃습니다.</li>
          <li class="text-gray-100">그 외의 모든 경우에는 플레이어가 자신의 베팅을 돌려받습니다.</li>
        </ol>
        <p class="text-gray-100">
          투표가 끝나기 전에 플레이어가 게임에서 연결이 끊어진 경우, 게임은 취소되고 베팅은 플레이어들에게 반환됩니다.
          <span v-if="!$store.state.safe_mode">완료된 각 게임에 대해 플랫폼은 각 패배한 베팅의 3%를 수수료로 취합니다.</span>
        </p>
      </div>

    <template #modal-footer="{ cancel }">
      <div class="d-flex">
        <b-btn variant="primary" @click="cancel()">{{ $t('close') }}</b-btn>
      </div>
    </template>
  </b-modal>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import LocaleSelector from '@/components/localeSelector.vue'

export default {
  components: {
    LocaleSelector
  },
  computed: {
    ...mapGetters(['USER_ME', 'g_game_state'])
}
}
</script>